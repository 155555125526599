@import '../../scss/imports';

#user-dropdown-button {
  width: rem(32px);
  height: 32px;

  .md-btn--text {
    width: 100%;
    height: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
}
