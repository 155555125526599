@import '../../scss/imports';

#app-dropdown-list{
  padding-bottom: 0px;
}
.app-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top:rem(24px);
  padding-top: rem(16px);

  .app-container-view-all {
    align-items: center;
    background-color: #F5F5F5;
    box-sizing: border-box;
    display:flex;
    font-size: 12px;
    height: rem(39px);
    justify-content: center;
    margin-top:rem(24px);
    text-transform: capitalize;
    width: 100%;
  }

  .app-icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: rem(82px);
    justify-content: flex-end;
    margin: rem(0px 6px);
    max-width: rem(64px);
    min-width: rem(64px);
    padding: rem(8px 0);
    width: rem(64px);

    .app-name {
      font-size: rem(12px);
      margin-top: rem(10px);
      text-transform: capitalize;
    }
  }
}
