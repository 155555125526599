@import '../../scss/imports';

.header-menu-btn {
  color: $header-action-button-color;

  &.md-btn--text {
    height: 100%;
    margin: 0;
    min-width: 100%;
    padding: 0;
    width: 100%;
  }
}

.header-menu-btn-toggle {
  &.md-btn--text {
    min-width: rem(20px);
  }
}

#notifications-dropdown-toggle {
  display: flex;
  justify-content: flex-end;
}
