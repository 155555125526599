@import '../../scss/imports';

#header-container {
  background-color: $header-background-color;
  z-index: 19;

  &.sticky {
    box-shadow: 0 4px 2px rgba(6, 6, 6, 0.33);
  }
}

#header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: rem(64px);
  width: 100%;

  .wrapper {
    height: 100%;
    max-width: rem(856px);

    & > .row {
      height: 100%;
    }
  }

  .logo-container {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: rem(16px);
    width: rem(236px);

    img {
      cursor: pointer;
      width: rem(103px);
    }

    button,
    img {
      display: inline-block;
    }
  }

  .header-action-container {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: rem(16px);
    padding-right: rem(16px);
  }

  .header-action-button {
    color: $white;
    margin-left: rem(8px);
    margin-right: rem(8px);
    min-width: initial;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: darken($white, 10%);
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .chip {
    background-color: transparent;
    border: 1px solid $white;
    border-radius: rem(12px);
    color: $white;
    flex-grow: 0;
    font-size: rem(14px);
    margin-right: rem(4px);
    padding: rem(2px 6px);
    text-transform: lowercase;
  }

  // ======= mobile header
  &.header-mobile {
    .header-menu-button-container {
      button {
        margin: rem(0 16px);
      }
    }

    .header-action-container {
      opacity: 1;
    }

    .header-action-container,
    .header-menu-button-container {
      display: inline-block;
    }
  }
  // ======= END mobile header

  // ======= desktop header
  &.header-desktop {
    justify-content: space-between;
    padding: rem(0 20px);

    .desktop-header-actions {
      align-items: center;
      display: flex;
      justify-content: space-evenly;

      .desktop-header-action-child {
        margin-right: rem(16px);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  // ======= END desktop header
}

.header-avatar-button {
  height: rem(48px);
  width: rem(48px);

  .user-avatar {
    font-size: rem(24px);
    height: rem(48px);
    width: rem(48px);
  }
}

.drawer-header {
  background-color: $primary-color;
  height: rem(145px);
  margin-bottom: rem(16px);
  padding: rem(24px);

  .md-btn--hover {
    background: transparent;
  }

  .md-btn--text {
    padding: 0;
    text-transform: capitalize;
  }

  .mobile-sign-out-button {
    display:flex;
    width: 100%;
  }

  .header-avatar-button {
    color: $white;
    margin: rem(20px 0 27px);

    .md-icon {
      color:$white;
      margin-left: rem(10px);
    }
  }

  .header-avatar-button-detail-container {
    padding-left: rem(20px);

    .header-avatar-button-detail {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    .name {
      font-size: rem(18px);
    }

    .email {
      font-size: rem(12px);
    }
  }

  .logo-container {
    img {
      cursor: pointer;
      width: rem(204px);
    }
  }
}

#user-button-mobile {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.header-avatar-button {
  height: rem(48px);
  width: rem(48px);

  .user-avatar {
    border: rem(2px) solid #FFF;
    flex-shrink: 0;
    font-size: rem(24px);
    height: rem(48px);
    width: rem(48px);
  }
}

.mobile-sign-out-button {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: rem(24px);
  width: 100%;

  .mobile-sign-out-icon {
    align-items: center;
    display: flex;
    margin-right: rem(24px);
  }
}

//=========== user dropdown animation
.collapse-button {
  cursor: pointer;
  transition: 250ms ease-in-out;

  &.expanded {
    transform: rotate(-180deg);
  }
}

.expansion-content {
  max-height: 0;
  overflow: hidden;

  &.expanded {
    animation: slide-open 450ms ease-in-out;
    max-height: rem(1000px);
  }

  &.closed {
    animation: slide-closed 350ms ease-in-out;
    max-height: 0;
  }
}

@keyframes slide-open {
  0% {
    max-height: rem(0px);
  }

  100% {
    max-height: rem(1000px);
  }
}

@keyframes slide-closed {
  0% {
    max-height: rem(1000px);
    min-height: 0;
  }

  100% {
    max-height: rem(0px);
  }
}
//=========== END user dropdown animation
