@import '../../scss/imports';

// LOADING SPINNER

$spinner-color: rgba($primary-color, .4);

.loading-spinner-container {
  background: rgba(#000, .4);
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.loading-spinner-dual-ring {
  display: inline-block;
  height: rem(256px);
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: rem(256px);

  &:after {
    animation: loading-spinner-dual-ring 1.2s linear infinite;
    border: 5px solid $spinner-color;
    border-color: $spinner-color transparent $spinner-color transparent;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 244px;
    margin: 1px;
    width: 244px;
  }
}

@keyframes loading-spinner-dual-ring {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    border-color: $spinner-color transparent $spinner-color transparent;
    transform: rotate(0deg);
  }
  25% {
    border-color: #1abc9c transparent  #1abc9c transparent;
  }
  50% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    border-color: #9b59b6 transparent  #9b59b6 transparent;
    transform: rotate(900deg);
  }
  75% {
    border-color: #e74c3c transparent  #e74c3c transparent;
  }
  100% {
    transform: rotate(1800deg);
  }
}

@keyframes rotate {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(0deg);
  }
  50% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(900deg);
  }
  100% {
    transform: rotate(1800deg);
  }
}
