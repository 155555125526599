@import '../../scss/imports';

#app-dropdown-button {
  height: rem(24px);
  width: rem(24px);
}

#app-dropdown-toggle {
  height: 100%;
  margin: 0;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#app-dropdown {
  .md-list {
    width: rem(264px);
  }
}
