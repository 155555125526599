@import '../../scss/imports';

.user-menu-container {
  display: flex;
  flex-direction: column;
  padding: rem(16px);

  .user-menu-top {
    align-items: center;
    display: flex;
    margin-bottom: rem(10px);

    .user-avatar {
      height: rem(64px);
      flex-shrink: 0;
      font-size: rem(26px);
      margin-right: rem(16px);
      width: rem(64px);
    }

    .user-details {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .user-name {
        font-family: $font-demi;
        font-size: rem(16px);
        font-weight: bold;
        margin-bottom: rem(6px);
      }

      .user-email {
        color: $secondary-text-color;
        font-size: rem(13px);
      }
    }
  }

  .user-menu-bottom {
    display: flex;
    flex-direction: column;

    .user-bottom-menu-items {
      align-items: center;
      border-radius: rem(4px);
      display: flex;
      font-size: rem(14px);
      line-height: rem(16px);

      .user-bottom-menu-icon {
        margin-right: rem(16px);
      }
    }
  }
}
