/* APP COLORS */
$black: #000;
$white: #FFFFFF;
$primary-color: #FF5722;
$primary-color-dark: #E83902;
$secondary-color: #2196F3;
$priority-color: #F11A1A;
$action-color: $secondary-color;
$label-color: #878787;
$disabled-color: #C3C3C3;
$text-color: #121212;
$secondary-text-color: rgba($black, .6);
$body-bg-color: #FAFAFA;
$header-background-color: $primary-color;
$header-action-button-color: $white;
$light-text-color: rgba($white, .8);

$border: 1px solid $secondary-text-color;

/* TEXTURES */
$border-radius: rem(4px);
$box-shadow: rem(0px 2px 4px) rgba($black, .2 );
$md-box-shadow: rem(0 2px 2px 0) rgba(0, 0, 0, 0.14), rem(0 1px 5px 0) rgba(0, 0, 0, 0.12), rem(0 3px 1px -2px) rgba(0, 0, 0, 0.2);


/* FONTS */
$font-path: '../assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial;
$font-light: 'Avenir Next W01 Light', Arial;
$font-regular: 'AvenirNextLTW01-Regular', Arial;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial;
$font-demi: 'Avenir Next LT W01 Demi', Arial;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial;

/* SCREEN SIZES */
$xxs: em(400);
$xs: em(480);
$sm: em(600);
$smt: em(800);
$md: em(960);
$lg: em(1280);
$xl: em(1440);
$ipad: em(768);
$landscape: em(1024);

$wrapper: rem(1200px);
